import React from 'react';

export default class GithubSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <a target="_blank" href="https://github.com/MrMathur">
        <svg width="48" height="48" viewBox="0 0 20 20" className="fill-slate-600 hover:fill-orange-400" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_67_103)">
            <path d="M10.0149 9.94903C10.0149 9.94903 10.0049 9.94903 9.9999 9.94903H9.9849C8.7939 9.94903 7.7449 9.64602 7.1239 10.217C6.7529 10.559 6.5969 10.971 6.5969 11.414C6.5969 13.266 8.0799 13.494 9.9859 13.494H10.0149C11.9199 13.494 13.4039 13.265 13.4039 11.414C13.4039 10.971 13.2479 10.558 12.8769 10.217C12.2549 9.64602 11.2059 9.94903 10.0149 9.94903ZM8.3929 12.48C8.0299 12.48 7.7369 12.072 7.7369 11.57C7.7369 11.068 8.0299 10.662 8.3929 10.662C8.7559 10.662 9.0499 11.068 9.0499 11.57C9.0509 12.072 8.7569 12.48 8.3929 12.48ZM11.6059 12.48C11.2429 12.48 10.9489 12.072 10.9489 11.57C10.9489 11.068 11.2429 10.662 11.6059 10.662C11.9679 10.662 12.2619 11.068 12.2619 11.57C12.2629 12.072 11.9689 12.48 11.6059 12.48ZM9.9999 0.400024C4.6979 0.400024 0.399902 4.69802 0.399902 10C0.399902 15.302 4.6979 19.6 9.9999 19.6C15.3019 19.6 19.5999 15.302 19.5999 10C19.5999 4.69802 15.3019 0.400024 9.9999 0.400024ZM10.8759 13.939C10.7039 13.939 10.3619 13.939 9.9999 13.941C9.6379 13.939 9.2959 13.939 9.1239 13.939C8.3639 13.939 5.3519 13.88 5.3519 10.25C5.3519 9.41603 5.6379 8.80502 6.1069 8.29502C6.0329 8.11102 6.0289 7.06302 6.4269 6.05902C6.4269 6.05902 7.3429 6.15902 8.7279 7.11002C9.0169 7.02902 9.5089 6.98802 9.9999 6.98802C10.4909 6.98802 10.9819 7.02902 11.2729 7.10902C12.6579 6.15802 13.5739 6.05802 13.5739 6.05802C13.9719 7.06202 13.9689 8.11102 13.8939 8.29402C14.3629 8.80402 14.6489 9.41502 14.6489 10.249C14.6479 13.881 11.6359 13.939 10.8759 13.939Z" />
          </g>
          <defs>
            <clipPath id="clip0_67_103">
              <rect width="20" height="20"/>
            </clipPath>
          </defs>
        </svg>
      </a>      
    );
  }
}