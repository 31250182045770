import React from 'react';

export default class DribbbleSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <a target="_blank" href="https://dribbble.com/arpitmathur19">
        <svg width="48" height="48" viewBox="0 0 20 20" className="fill-slate-600 hover:fill-orange-400" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_67_105)">
            <path d="M10.2599 9.98203C10.2929 9.97003 10.3279 9.96103 10.3629 9.95103C10.2799 9.76602 10.1879 9.57402 10.0839 9.36702C8.2039 9.92402 6.4039 9.92902 6.0829 9.92402C6.0799 9.94902 6.0799 9.97503 6.0799 10C6.0799 10.945 6.4199 11.853 7.0379 12.566C7.2439 12.234 8.3359 10.605 10.2599 9.98203ZM7.6229 13.113C8.3119 13.641 9.1349 13.92 9.9999 13.92C10.5169 13.92 11.0189 13.82 11.4939 13.625C11.4279 13.244 11.1989 12.084 10.6719 10.703C8.5509 11.453 7.7499 12.865 7.6229 13.113ZM12.5549 7.02702C11.8439 6.41602 10.9379 6.08002 9.9999 6.08002C9.7159 6.08002 9.4309 6.11102 9.1499 6.17402C9.3549 6.44902 9.9819 7.32002 10.5709 8.39702C11.8539 7.90402 12.4339 7.19302 12.5549 7.02702ZM9.7049 8.66402C9.0969 7.59202 8.4639 6.70902 8.2899 6.47302C7.2229 6.99002 6.4379 7.97902 6.1769 9.13102H6.1939C6.5999 9.13102 8.0429 9.09802 9.7049 8.66402ZM11.5139 10.496C11.9789 11.789 12.1929 12.863 12.2539 13.207C13.1059 12.607 13.6879 11.686 13.8629 10.664C13.6889 10.611 13.0609 10.443 12.2709 10.443C12.0129 10.443 11.7579 10.461 11.5139 10.496ZM9.9999 0.400024C4.6979 0.400024 0.399902 4.69802 0.399902 10C0.399902 15.302 4.6979 19.6 9.9999 19.6C15.3019 19.6 19.5999 15.302 19.5999 10C19.5999 4.69802 15.3019 0.400024 9.9999 0.400024ZM9.9999 14.697C7.4079 14.697 5.3009 12.59 5.3009 10C5.3009 7.40802 7.4079 5.30102 9.9999 5.30102C12.5909 5.30102 14.6979 7.40902 14.6979 10C14.6979 12.59 12.5909 14.697 9.9999 14.697ZM10.9219 9.07403C11.0089 9.25403 11.0899 9.43102 11.1639 9.60502C11.1869 9.66202 11.2119 9.71802 11.2349 9.77502C11.5119 9.74202 11.8079 9.72602 12.1169 9.72602C12.9709 9.72602 13.6799 9.85102 13.9179 9.89802C13.8959 9.04802 13.5949 8.22202 13.0659 7.55802C12.9059 7.76402 12.2479 8.52102 10.9219 9.07403Z"/>
          </g>
          <defs>
            <clipPath id="clip0_67_105">
              <rect width="20" height="20" />
            </clipPath>
          </defs>
        </svg>
      </a>      
    );
  }
}